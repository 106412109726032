var len = 0,
    counter = 0,
    tableauImgs = [],
    ancienLen = 0;

function chargementImages(tableau){

    // console.log(tableau);

    ancienLen = len;
    // pour tous les .preload du dom
    tableau.forEach(function(element) {
        if(tableauImgs.includes(element) === false){
            tableauImgs.push(element);
            len++;

            //console.log(element);
            window['myImage'+len] = new Image();
            window['myImage'+len].src = element;
            
            window['myImage'+len].addEventListener( 'load', incrementCounter, false );

        }
    });

    if(ancienLen === len){
        console.log('pas de nouvelles images à charger');
        // console.log('length', tableauImgs.length);

        sortie_images = true;   
    }

    function incrementCounter() {
        counter++;
        if ( counter === len ) {
            // console.log('toutes les nouvelles images sont chargées');
            // console.log('length', tableauImgs.length);
            sortie_images = true;
        }
    }

}