document.addEventListener("DOMContentLoaded", function() {

    //------------------------------//
    // APPELS AU PREMIER CHARGEMENT //
    //------------------------------//
    history.pushState({}, '', location);
    
    //requestion animation frame qui listen que les images soient chargées
    le_raf_chargement();

    if(!isMobile()){
        document.querySelector('body').classList.add('desktop');
    }else{
        document.querySelector('body').classList.add('mobile');
    }

    // s'il y a des images à charger
    if(document.querySelectorAll('.preload').length !== 0){
        
        var imgsProchainePage = [];

        for (var i = 0; i < document.querySelectorAll('.preload').length; i++) {
            imgsProchainePage.push(document.querySelectorAll('.preload')[i].getAttribute('data-url'));
        }
        
        chargementImages(imgsProchainePage);

    }else{
        sortie_images = true;
    }

    raf();



    // LISTE CUSTOM EASE
    CustomEase.create("QuartOut", "0.860, 0.000, 0.070, 1.000"); //0.26, 1.00, 0.48, 1.00
    CustomEase.create("QuartOutOld", "0.26, 1.00, 0.48, 1.00"); //0.26, 1.00, 0.48, 1.00
    CustomEase.create("CubicIn", "0.40, 0.00, 0.68, 0.06");
    
}); 


// on vérifie que l'anim de sortie est bonne, que la requete ajax est faite et que les images sont chargées
function le_raf_chargement() {

    raf_chargement = requestAnimationFrame(le_raf_chargement);
    
    if(sortie_ok && ajax_ok && sortie_images){
    	if(premier_appel){
    		initPage();
    	}else{
        	updatePage(contenu_nouvelle_page);
        }
        cancelAnimationFrame(raf_chargement);
    
    }
}






let sortie_ok = true, ajax_ok = true, lien_en_cours = false, sortie_lottie = false, raf_loader, raf_chargement, sortie_images = false, links, premier_appel = true, contenu_nouvelle_page, le_raf, ancien_height = 0, animEnCours = false;
let W = window.innerWidth, H = window.innerHeight;



// fonction appellée à chaque lancement de page
let initPage = function() {
	sortie_ok = false;
	ajax_ok = false;
    sortie_images = false;

    lien_en_cours = false;

   	// on lance les animations d'entree
    animationsEntree();

    // maj
    // quand clique on lien
    links = document.querySelectorAll('a');
    for(var a = 0; a < links.length; a++){
        links[a].removeEventListener('click', onClick);
        links[a].addEventListener('click', onClick);
    }

    function onClick (event) {
        
        if( !event.target.classList.contains('externe') ){

            event.preventDefault();
            //temp
            event.stopPropagation();

            // lancement requete ajax qu'une seule fois
            if( lien_en_cours === false ){

                // alert();

                lien_en_cours = true;
                let href = this.getAttribute('href');

                history.pushState({}, '', href);
                loadPage(href);
                le_raf_chargement();

                return false;

            }else{ return false; }
        }
    }
    

},

// quand get() terminé
ajaxLoad = function(html) {
    contenu_nouvelle_page = html;
    ajax_ok = true;

    // code pour charger les images dès que ajax terminé
    let parser = new DOMParser();
    let doc = parser.parseFromString(html, "text/html");
    
    if(doc.querySelectorAll('.preload').length !== 0){

        var imgsProchainePage = [];
        
        // doc.querySelectorAll('.preload').forEach(x => {
        //     imgsProchainePage.push(x.getAttribute('data-img'));
        // });
        for (var i = 0; i < doc.querySelectorAll('.preload').length; i++) {
            imgsProchainePage.push(doc.querySelectorAll('.preload')[i].getAttribute('data-url'));
        }

        //
        // console.log(doc.querySelectorAll('.preload')[0].getAttribute('data-url'));
        // let temp = new Image();
        // temp.src = doc.querySelectorAll('.preload')[0].getAttribute('data-url');
        // console.log(temp.client);

        chargementImages(imgsProchainePage);

    }else{
    	sortie_images = true;
    }

},
// animations de sortie sorties
loadPage = function(href) {

    let xhr = new XMLHttpRequest(),
        method = "GET",
        url = href;

    xhr.open(method, url, true);
    xhr.onreadystatechange = function () {
        if(xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
            ajaxLoad(xhr.responseText);
        }
    };
    xhr.send(); 

    animationsSortie();

},
// mise à jour des données de la page
updatePage = function(html) {

    let parser = new DOMParser();
    let doc = parser.parseFromString(html, "text/html");
    let liste_class = doc.body.getAttribute('class');
    
    // maj titre de la page
    document.title = doc.querySelector('title').innerHTML;

    // maj class du body
    document.body.setAttribute('class', doc.body.getAttribute('class'));
    

    if(!isMobile()){
        document.body.classList.add('desktop');
    }else{
        document.body.classList.add('mobile');
    }

    // maj contenu #main
    // console.log(doc.getElementById('main').innerHTML);
    document.getElementById('main').innerHTML = doc.getElementById('main').innerHTML;
    
    // on lance la nouvelle page
    initPage();
};




// gestion bouton prev/next navigateur
window.onpopstate = function(e) {
    
    if(e.state !== null) {

        loadPage(location.href);
        le_raf_chargement();
    }
};


function raf() {
    
    le_raf = requestAnimationFrame(raf);

    if(document.getElementById('main').clientHeight != ancien_height && !isMobile() && le_scroll !== null ){
        // console.log('changement height vs section');
        le_scroll.resize();
        ancien_height = document.getElementById('main').clientHeight;
    }


    // console.log(sortie_ok, ajax_ok, sortie_images);

}


let posX = 0, posY = 0;
document.addEventListener("mousemove", function(event){
    posX = event.pageX;
    posY = event.pageY;

});












// ENTREE
// ENTREE
let le_scroll = null;
function animationsEntree(){

    // MAJ CURRENT MENU
    document.querySelectorAll('.innerHover .lien').forEach(x => x.classList.remove('current'));
    if(document.body.classList.contains('home') || document.body.classList.contains('single-membres')){
        document.querySelectorAll('.innerHover .lien')[0].classList.add('current');
    }else if(document.body.classList.contains('post-type-archive-evenementiel')  || document.body.classList.contains('single-evenementiel')){
        document.querySelectorAll('.innerHover .lien')[2].classList.add('current');
    }else if(document.body.classList.contains('post-type-archive-identitemusicale') || document.body.classList.contains('single-identitemusicale')){
        document.querySelectorAll('.innerHover .lien')[1].classList.add('current');
    }else if(document.body.classList.contains('page-template-tendances')){
        document.querySelectorAll('.innerHover .lien')[3].classList.add('current');
    }else if(document.body.classList.contains('page-template-contact')){
        document.querySelectorAll('.innerHover .lien')[4].classList.add('current');
    }

    window.scrollTo(0, 0);

    if(premier_appel){
        premier_appel = false;
        TweenMax.to('#main, #logo, #toMenu', 0.8, {opacity:1, ease:Power2.easeInOut});
    }else{
        TweenMax.to('#main', 0.8, {opacity:1, ease:Power2.easeInOut});
    }

    if(!isMobile() && document.querySelector('.vs-section') !== null){

        //maj menu
        leZ++;

        dernierHov = indexInParent(document.querySelector('.lien.current').parentNode);
        document.querySelectorAll('.innerCovers div')[indexInParent(document.querySelector('.lien.current').parentNode)].style.zIndex = leZ;

        // if(!document.body.classList.contains('single-membres')){
        le_scroll = new global_custom2.default({
            preload: true,
            noscrollbar: true,
            native: false,
            section: document.querySelector('.vs-section'),
            divs: document.querySelectorAll('.vs-div')
            //ease: 0.08

        });

        le_scroll.vs.options.mouseMultiplier = 0.4;

        le_scroll.resize();
        le_scroll.init();

        le_scroll.vs.on(function(){
            if(
                document.getElementById('form').getBoundingClientRect().top < window.innerHeight/10 &&
                !document.body.classList.contains('logoBlanc')
            ){
                document.body.classList.add('logoBlanc');
            }else if(
                document.getElementById('form').getBoundingClientRect().top > window.innerHeight/10 &&
                document.body.classList.contains('logoBlanc')
            ){
                document.body.classList.remove('logoBlanc');
            }
        });
        // }
    }

    if(document.body.classList.contains('home')){
        eventsHome();
    }else if(document.body.classList.contains('archive')){
        eventsArchive();
    }


    document.getElementById('submit').addEventListener('click', function(e){ 
        if(
            document.getElementById('name').checkValidity() &&
            document.getElementById('mail').checkValidity() &&
            document.getElementById('phone').checkValidity() &&
            document.getElementById('company').checkValidity() &&
            document.getElementById('message').checkValidity()
        ){
            e.preventDefault();
            submitForm();
        }
    });


}




// SORTIE
// SORTIE
function animationsSortie(){

    if(document.getElementById('menu').classList.contains('actif')){
        document.getElementById('menu').classList.remove('actif');
        TweenMax.set('#main', {opacity:0});
        TweenMax.to('.innerHover a', 1, {opacity:0});
        TweenMax.to('#menu', 1, {y:"-100%", ease:"QuartOut"});
        TweenMax.to('#menu > div', 1, {y:"100%", ease:"QuartOut", onComplete:function(){
            TweenMax.set('.va', {y:"-70%"});
            TweenMax.set('.innerHover a', {y:"-80%"});
            document.getElementById('menu').style.display = "none";
            animEnCours = false;

            finSortie();

        }});
    }else{
        TweenMax.to('#main', 0.8, {opacity:0, ease:Power2.easeInOut, onComplete:function(){
            finSortie();
        }});
    }
    
}

function finSortie(){
    if(!isMobile() && document.querySelector('.vs-section') !== null){
        le_scroll.vars.current = le_scroll.vars.target = 0;
        if(le_scroll !== null){le_scroll.destroy();}
        le_scroll = null;
    }
    sortie_ok = true;
}






// ON CLICK 
document.getElementById('toMenu').addEventListener('click', function(){
    if(!animEnCours){
        animEnCours = true;
        document.getElementById('menu').style.display = "block";
        document.getElementById('menu').classList.add('actif');
        TweenMax.to('#menu, #menu > div', 1, {y:"0%", ease:"QuartOut"});
        TweenMax.to('.va', 1.4, {y:"-50%", ease:Power2.easeOut, onComplete:function(){
            animEnCours = false;
            if(le_scroll !== null){
                le_scroll.off();
            }
        }});
        TweenMax.staggerTo('.innerHover a', 1, {y:"0%", opacity:1, ease:Power1.easeOut, delay:0.1}, -0.1);
    }
});
document.getElementById('toFermer').addEventListener('click', function(){
    if(!animEnCours){
        animEnCours = true;
        if(le_scroll !== null){
            le_scroll.on();
        }

        document.getElementById('menu').classList.remove('actif');

        TweenMax.to('.innerHover a', 1, {opacity:0});
        TweenMax.to('#menu', 1, {y:"-100%", ease:"QuartOut"});
        TweenMax.to('#menu > div', 1, {y:"100%", ease:"QuartOut", onComplete:function(){
            TweenMax.set('.va', {y:"-70%"});
            TweenMax.set('.innerHover a', {y:"-80%"});
            document.getElementById('menu').style.display = "none";
            animEnCours = false;
        }});
    }
});

let leZ = 0, dernierHov;
document.querySelectorAll('.lien').forEach(x => {
    x.addEventListener('mouseenter', function(){
        leZ++;

        if(dernierHov !== indexInParent(x.parentNode)){
            TweenMax.fromTo(document.querySelectorAll('.innerCovers div')[indexInParent(x.parentNode)], 0.6, {scale:1.1}, {scale:1, ease:Power2.easeOut});
        }
        dernierHov = indexInParent(x.parentNode);
        document.querySelectorAll('.innerCovers div')[indexInParent(x.parentNode)].style.zIndex = leZ;

    });
});
document.querySelector('.innerHover').addEventListener('mouseleave', function(){
    leZ++;
    document.querySelectorAll('.innerCovers div')[indexInParent(document.querySelector('.lien.current').parentNode)].style.zIndex = leZ;
    if(dernierHov !== indexInParent(document.querySelector('.lien.current').parentNode)){
        TweenMax.fromTo(document.querySelectorAll('.innerCovers div')[indexInParent(document.querySelector('.lien.current').parentNode)], 0.6, {scale:1.1}, {scale:1, ease:Power2.easeOut});
    }
    dernierHov = indexInParent(document.querySelector('.lien.current').parentNode);
});
















// SLIDER
// SLIDER
// SLIDER
// SLIDER
function eventsHome(){

    TweenMax.to('h1 div div', 1.5, {y:'0%', delay:0.8, ease:Power2.easeOut});
    TweenMax.to('h1 span div', 1.4, {y:'0%', delay:0.5, ease:Power2.easeOut});

    document.querySelector('#slider a').setAttribute('href', document.querySelector('.titres .current').getAttribute('data-url'));
    document.getElementById('nextSlide').addEventListener('click', function(){
        if(!animEnCours){
            animEnCours = true;

            // MAJ IMGS
            TweenMax.to('.img1 .current', 1.2, {x:"100%", ease:"QuartOut"});
            TweenMax.to('.img1 .current div', 1.2, {x:"-100%", ease:"QuartOut"});
            if(document.querySelector('.img1 .current').nextElementSibling !== null){
                TweenMax.fromTo(document.querySelector('.img1 .current').nextElementSibling, 1.2, {x:"-100%"}, {x:"0%", ease:"QuartOut"});
                TweenMax.fromTo(document.querySelector('.img1 .current').nextElementSibling.querySelector('div'), 1.2, {x:"100%"}, {x:"0%", ease:"QuartOut", onComplete:function(){
                    const elmt = document.querySelector('.img1 .current').nextElementSibling;
                    document.querySelector('.img1 .current').classList.remove('current');
                    elmt.classList.add('current');
                    animEnCours = false;
                }});
            }else{
                TweenMax.fromTo(document.querySelectorAll('.img1 div')[0], 1.2, {x:"-100%"}, {x:"0%", ease:"QuartOut"});
                TweenMax.fromTo(document.querySelectorAll('.img1 div')[0].querySelector('div'), 1.2, {x:"100%"}, {x:"0%", ease:"QuartOut", onComplete:function(){
                    const elmt = document.querySelectorAll('.img1 div')[0];
                    document.querySelector('.img1 .current').classList.remove('current');
                    elmt.classList.add('current');
                    animEnCours = false;
                }});
            }

            TweenMax.to('.img2 .current', 1.2, {x:"-100%", ease:"QuartOut"});
            TweenMax.to('.img2 .current div', 1.2, {x:"100%", ease:"QuartOut"});
            if(document.querySelector('.img2 .current').nextElementSibling !== null){
                TweenMax.fromTo(document.querySelector('.img2 .current').nextElementSibling, 1.2, {x:"100%"}, {x:"0%", ease:"QuartOut"});
                TweenMax.fromTo(document.querySelector('.img2 .current').nextElementSibling.querySelector('div'), 1.2, {x:"-100%"}, {x:"0%", ease:"QuartOut", onComplete:function(){
                    const elmt = document.querySelector('.img2 .current').nextElementSibling;
                    document.querySelector('.img2 .current').classList.remove('current');
                    elmt.classList.add('current');
                    animEnCours = false;
                }});
            }else{
                TweenMax.fromTo(document.querySelectorAll('.img2 div')[0], 1.2, {x:"100%"}, {x:"0%", ease:"QuartOut"});
                TweenMax.fromTo(document.querySelectorAll('.img2 div')[0].querySelector('div'), 1.2, {x:"-100%"}, {x:"0%", ease:"QuartOut", onComplete:function(){
                    const elmt = document.querySelectorAll('.img2 div')[0];
                    document.querySelector('.img2 .current').classList.remove('current');
                    elmt.classList.add('current');
                    animEnCours = false;
                }});
            }


            // MAJ TITRE
            TweenMax.to('.titres .current', 0.6, {opacity:0, ease:Power2.easeInOut, onComplete:function(){
                const elmt = document.querySelector('.titres .current');
                if(elmt.nextElementSibling !== null){
                    elmt.nextElementSibling.classList.add('current');
                    elmt.classList.remove('current');
                    TweenMax.to(elmt.nextElementSibling, 0.6, {opacity:1, ease:Power2.easeInOut});
                }else{
                    document.querySelectorAll('.titres h3')[0].classList.add('current');
                    elmt.classList.remove('current');
                    TweenMax.to(document.querySelectorAll('.titres h3')[0], 0.6, {opacity:1, ease:Power2.easeInOut});
                }
                document.querySelector('#slider a').setAttribute('href', document.querySelector('.titres .current').getAttribute('data-url'));
                
            }});

            //MAJ NUM
            TweenMax.to('.numeros span', 0.6, {opacity:0, ease:Power2.easeInOut, onComplete:function(){
                if(parseInt(document.querySelector('.numeros span').innerHTML) === document.querySelectorAll('.titres h3').length){
                    document.querySelector('.numeros span').innerHTML = 0;
                }
                document.querySelector('.numeros span').innerHTML = parseInt(document.querySelector('.numeros span').innerHTML) + 1;
                document.querySelector('.numeros span').innerHTML = "0" + document.querySelector('.numeros span').innerHTML;
                TweenMax.to('.numeros span', 0.6, {opacity:1, ease:Power2.easeInOut});
            }});

            //MAJ LIEN
        }
    });
}








// EVENTS ARCHIVE
// EVENTS ARCHIVE
// EVENTS ARCHIVE
// EVENTS archive
function eventsArchive(){
    document.querySelectorAll('#filtres div').forEach(x => {
        x.addEventListener('click', function(e){
            if(x.classList.contains('actif')){
                TweenMax.to('.push', 0.5, {opacity:0, onComplete:function(){
                    document.querySelectorAll('#filtres div').forEach(x => x.classList.remove('actif')); 
                    document.querySelectorAll('.push').forEach(x => {
                        x.classList.remove('agauche', 'adroite', 'actif', 'pasActif');
                    });

                    let i = 0;
                    document.querySelectorAll('.push').forEach(x => {
                        i++;
                        x.querySelector('.numero').innerHTML = "0"+i;
                        if(i % 2 === 0 ){
                            x.classList.add('adroite');   
                        }else{
                            x.classList.add('agauche');   
                        }
                    });

                    TweenMax.to('.push', 0.5, {opacity:1});
                }});
            }else{
                document.querySelectorAll('#filtres div').forEach(x => x.classList.remove('actif'));
                e.target.classList.add('actif');

                TweenMax.to('.push', 0.5, {opacity:0, onComplete:function(){
                    document.querySelectorAll('.push').forEach(x => {

                        x.classList.remove('agauche', 'adroite', 'actif', 'pasActif');

                        if(x.getAttribute('data-cat') === e.target.getAttribute('data-slug')){
                            x.classList.add('actif');
                        }else{
                            x.classList.add('pasActif');
                        }
                    });

                    let i = 0;
                    document.querySelectorAll('.push.actif').forEach(x => {
                        i++;

                        x.querySelector('.numero').innerHTML = "0"+i;
                        if(i % 2 === 0 ){
                            x.classList.add('adroite');   
                        }else{
                            x.classList.add('agauche');   
                        }
                        
                    });

                    TweenMax.to('.push', 0.5, {opacity:1});
                }});
            }
            
        });
    });
}




// SCROLL EVENTS
window.addEventListener('scroll', function() {
    if(
        document.getElementById('form').getBoundingClientRect().top < window.innerHeight/10 &&
        !document.body.classList.contains('logoBlanc')
    ){
        document.body.classList.add('logoBlanc');
    }else if(
        document.getElementById('form').getBoundingClientRect().top > window.innerHeight/10 &&
        document.body.classList.contains('logoBlanc')
    ){
        document.body.classList.remove('logoBlanc');
    }
});
